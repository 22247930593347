<template>
  <div class="page" style="margin-top: 40px">
    <div class="box_1">
      <div class="box_11">
        <div class="group_27 flex-row">
          <div class="box_12 justify-between">
            <div class="menufooter">
              <a
                href="http://misc.drcnet.com.cn/us/wangzsm.aspx"
                target="_blank"
                >网站声明</a
              >|<a
                href="http://company.drcnet.com.cn/lxwm.html#/contact"
                target="_blank"
                >联系我们</a
              >|<a
                href="http://misc.drcnet.com.cn/DRCNet.Users.Web/User/UserSuggestAdd1.aspx"
                target="_blank"
                >意见和建议</a
              >|<a href="mailto:chiefeditor@drcnet.com.cn">总监信箱</a>
            </div>

            <div class="group_28 flex-row">
              <div class="image-text_1 flex-row justify-between">
                <img
                  class="image_40"
                  referrerpolicy="no-referrer"
                  src="./images/psl1uodz7p81gavc5jy6kh4e882anzcp05web4d61da-8314-449f-95ae-d.png"
                />
                <div class="text-group_13 justify-between">
                  <p class="text_192">热线电话</p>
                  <p class="text_193">400-7070-678</p>
                </div>
              </div>
              <div
                class="image-text_1 flex-row justify-between"
                style="margin-left: 20px"
              >
                <img
                  class="image_40"
                  referrerpolicy="no-referrer"
                  src="./images/psow2nsjkpsioi8e0rens808h435m0jutxeadc0cd35-fc51-4e58-8cd5-0.png"
                />
                <div class="text-group_13 justify-between">
                  <p class="text_192">意见箱</p>
                  <p class="text_193" style="font-size: 20px">
                    Suggestions &amp; Complaints Box
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="box_footer">
            <div class="image-text_2 justify-between">
              <img
                class="image_42"
                referrerpolicy="no-referrer"
                src="./images/pswx18to1cb1g49m2aeg286pmn7cw58gbjjb2e2d3d1-d85e-4eea-9b3b-a.png"
              />
              <div class="text-group_14">服务号</div>
            </div>
            <div class="image-text_3 justify-between">
              <img
                class="image_43"
                referrerpolicy="no-referrer"
                src="./images/psptbnmrcibabug7n1v73ajj1889a68jkzqfc0833eb-a2c6-4a73-9ba5-5.png"
              />
              <div class="text-group_15">订阅号</div>
            </div>
            <div class="image-text_4 justify-between">
              <img
                class="image_44"
                referrerpolicy="no-referrer"
                src="./images/ps6wkx9va75rr1ycqqiwhargr8vmlp5ipl96691f13e-1475-4430-adfb-6.png"
              />
              <div class="text-group_16">安卓版</div>
            </div>
          </div>
        </div>

        <div class="line_f">
          <div class="image-text_5">
            <span class="text-group_17">
              <a
                href="https://www.drcnet.com.cn/announcement/zizhiimg1"
                target="_blank"
                class="f1"
                >增值电信业务经营许可证
                京ICP证030059号&nbsp;&nbsp;&nbsp;&nbsp;</a
              >
              <a
                href="https://www.drcnet.com.cn/announcement/zizhiimg2"
                target="_blank"
                class="f1"
                >增值电信业务经营许可证 京B2-20070136&nbsp;&nbsp;&nbsp;&nbsp;</a
              >
              <a
                href="https://www.drcnet.com.cn/announcement/zizhiimg3"
                target="_blank"
                class="f1"
                >出版物经营许可证
                新出发京批字第直210398号&nbsp;&nbsp;&nbsp;&nbsp;</a
              >
              京公网安备&nbsp;11010102002958号
            </span>
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958"
              target="_blank"
              ><img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="./images/ps6ffl5xm18wbpcw6629ufw2iblta3eh6505f5e895-0608-46f3-9f92-8a.png"
            /></a>
          </div>
          <div class="group_29 flex-row">
            <div class="image-text_6 flex-row justify-between">
              <span class="text-group_18">
                Copyright@1998-2024&nbsp;DRCnet.&nbsp;All&nbsp;Rights&nbsp;Reserved&nbsp;版权所有&nbsp;国研网
              </span>
              <img
                class="image_45"
                referrerpolicy="no-referrer"
                src="./images/psdtzjditsdsqqfp9rvaru6ifdyuyle3up18eab551-e063-4262-8045-01.png"
              />
            </div>
            <span class="text_196">建议浏览器：火狐、谷歌</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.box_11 {
  background-color: rgba(241, 244, 248, 1);
  width: 100%;
  border-top: 5px solid #bc3523;
  padding-top: 40px;
  .group_27 {
    width: 1103px;
    height: 136px;
    margin: 0 auto;
    .justify-between {
      float: left;
    }
    .box_12 {
      width: 613px;
      height: 115px;
      .menufooter {
        color: #5b5b5b;
        a {
          padding: 0 10px;
          color: #5b5b5b;
          font-size: 14px;
          text-decoration: none;
        }
      }
      .group_28 {
        width: 613px;
        height: 56px;
        margin-top: 43px;
        .image-text_1 {
          height: 56px;
          .image_40 {
            width: 56px;
            height: 56px;
            margin-left: 10px;
            margin-right: 10px;
            float: left;
          }
          .text-group_13 {
            padding-top: 8px;
            p {
              margin: 0;
            }
            .text_192 {
              font-size: 13px;
              color: #797979;
            }
            .text_193 {
              font-size: 22px;
              font-family: Arial;
              font-weight: bold;
              color: #5b5b5b;
            }
          }
        }
      }
    }
    .box_footer {
      float: right;

      .image-text_2 {
        width: 115px;
        height: 136px;
        margin-left: 93px;
        float: left;
        .text-group_14 {
          text-align: center;
        }
      }
      .image-text_3 {
        width: 115px;
        height: 136px;
        margin-left: 26px;
        float: left;
        .text-group_15 {
          text-align: center;
        }
      }
      .image-text_4 {
        width: 115px;
        height: 136px;
        margin-left: 26px;
        float: left;
        .text-group_16 {
          text-align: center;
        }
      }
      .justify-between {
        float: left;
      }
    }
  }
  .line_f {
    border-top: 1px solid #d6d7d8;
    padding-top: 10px;
    margin-top: 30px;
    padding-bottom: 10px;
    .image-text_5 {
      width: 962px;
      margin: 0 auto;
      .text-group_17 {
        color: rgba(116, 116, 116, 1);
        font-size: 12px;
        font-weight: 100;
        .f1 {
          font-family: "微软雅黑";
          font-size: 12px;
          color: #7e7e7e;
          line-height: 32px;
        }
        .thumbnail_1 {
          width: 18px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .group_29 {
      width: 641px;
      margin: 0 auto;
      margin-top: 8px;
      .justify-between {
        float: left;
      }
      .image-text_6 {
        width: 447px;
        .text-group_18 {
          color: rgba(116, 116, 116, 1);
          font-size: 12px;
          padding-right: 5px;
        }
        .image_45 {
          width: 19px;
          height: 23px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .text_196 {
        width: 171px;
        color: rgba(116, 116, 116, 1);
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .group_27 {
    width: 100% !important;
    .box_12 {
      width: 100% !important;
      height: 364px;
      .menufooter {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        a {
          padding: 0 2px !important;
        }
      }
      .group_28 {
        width: 100% !important;
        .justify-between {
          float: none !important;
          margin: 0px !important;
          margin-bottom: 16px !important;
        }
      }
    }
    .box_footer {
      width: 100%;
      height: 136px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      margin-top: 100px;
      .justify-between {
        margin: 0 !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
  .line_f {
    margin-top: 227px !important;
    .image-text_5 {
      width: 100% !important;
      text-align: center;
    }
    .group_29 {
      width: 100% !important;
      text-align: center;
      .image-text_6 {
        width: 100% !important;
      }
    }
  }
}
</style>