const TOKEN_KEY = 'token';
const NAME_KEY = 'username';
const STATE_KEY = 'userstate';
const localStorage = window.localStorage;

export const getToken = () => {
	return localStorage.getItem(TOKEN_KEY);
};

export const setToken = (token) => {
	localStorage.setItem(TOKEN_KEY, token);
};

export const removeToken = () => {
	localStorage.removeItem(TOKEN_KEY);
};

export const getName = () => {
	return localStorage.getItem(NAME_KEY);
};
export const setName = (name) => {
	localStorage.setItem(NAME_KEY, name);
};
export const removeName = () => {
	localStorage.removeItem(NAME_KEY);
};

export const getState = () => {
	return localStorage.getItem(STATE_KEY);
};
export const setState = (state) => {
	localStorage.setItem(STATE_KEY, state);
};
export const removeState = () => {
	localStorage.removeItem(STATE_KEY);
};

const Uid_KEY = 'uid';
const sessionStorage = window.sessionStorage;

export const getUid = () => {
	return sessionStorage.getItem(Uid_KEY);
};
export const setUid = (state) => {
	sessionStorage.setItem(Uid_KEY, state);
};
export const removeUid = () => {
	sessionStorage.removeItem(Uid_KEY);
};
