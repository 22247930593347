<template>
	<div>
		<!-- 移动端内容 -->
		<div v-if="isMobile && isMobileDefault != false">
			<!-- 左上角悬浮导航按钮 -->
			<Icon
				class="drawer-button"
				name="list-switch"
				size="30"
				@click.stop="toggleDrawer"
			/>
			<!-- 2024-1-31新需求，去掉除了登录内容的所有其他链接，登录改为注册改为一行 -->
			<Popup
				v-model="isDrawerOpen"
				position="right"
				:style="{ height: '100%', minWidth: '310px' }"
			>
				<slot name="nav-top-content-isMobile"></slot>
				<!-- <top>
       在父组件中使用子组件的插槽 
      <template v-slot:nav-top-content-isMobile>
        <p>这是父组件中使用的内容。</p>
      </template>
      </top>-->
				<div class="drawer-content" v-if="isMobileListDefault != false">
					<!-- navButtonListLeft 列表 -->
					<div class="userInfos">
						<div
							v-if="isLoginDefault != false"
							v-show="isShowLogin"
							@click="isMobileLoginTo"
							class="userInfosLogin"
						>
							登录
						</div>
						<div
							v-if="isLoginDefault != false"
							v-show="isShowName"
							class="userInfosInfo"
						>
							欢迎您，{{ userName }}
						</div>
						<div
							v-if="isLoginDefault != false"
							@click="loginOut"
							v-show="isShowName"
							class="userInfosOut"
						>
							退出
						</div>
						<div
							v-show="isShowLogin"
							class="userInfosPass"
							@click="
								regLink(
									'http://misc.drcnet.com.cn/DRCNet.Users.Web/User/UserPwdGet.aspx'
								)
							"
						>
							忘记密码
						</div>
						<div
							v-show="isShowLogin"
							class="userInfosReg"
							@click="
								regLink(
									'http://misc.drcnet.com.cn/DRCNet.Users.Web/User/UserReg1.aspx'
								)
							"
						>
							注册
						</div>
					</div>
				</div>
				<slot name="nav-top-content-isMobile-bottom"></slot>
				<!-- <top>
       在父组件中使用子组件的插槽 
      <template v-slot:nav-top-content-isMobile>
        <p>这是父组件中使用的内容。</p>
      </template>
      </top>-->
			</Popup>
			<!-- <Popup
				v-model="isDrawerOpen"
				position="right"
				:style="{ height: '100%', minWidth: '310px' }"
			>
				<slot name="nav-top-content-isMobile"></slot>
				<div class="drawer-content" v-if="isMobileListDefault != false">
					<CellGroup title="">
						<Cell
							v-if="isLoginDefault != false"
							v-show="isShowLogin"
							title="登录"
							@click="isMobileLoginTo"
						>
						</Cell>
						<Cell
							v-if="isLoginDefault != false"
							v-show="isShowName"
							:title="'欢迎您，' + userName"
						>
						</Cell>

						<Cell
							v-if="isLoginDefault != false"
							v-show="isShowName"
							@click="loginOut"
							title="退出"
						>
						</Cell>
						<Cell
							v-for="(item, index) in isMobileList"
							:key="index"
							:title="item.name"
							clickable
							@click="handleLink(item.toLink)"
						/>
					</CellGroup>
					<Collapse
						v-model="activeNames"
						v-if="databaseDefalut != false"
					>
						<CollapseItem title="特色数据库" name="1">
							<CellGroup>
								<Cell
									v-for="(item, index) in navDataBaseDropdown"
									:key="index"
									:title="item.name"
									clickable
									@click="handleLink(item.toLink)"
								/>
							</CellGroup>
						</CollapseItem>
					</Collapse>
				</div>
				<slot name="nav-top-content-isMobile-bottom"></slot>

			</Popup> -->
			<!-- 左侧悬浮抽屉导航 -->
			<!-- <aside :class="{ 'show-drawer': isDrawerOpen }" @click.stop>
				<ul>
					<li><a href="javascript:void(0)">Home</a></li>
					<li><a href="javascript:void(0)">About</a></li>
					<li><a href="javascript:void(0)">Services</a></li>
				</ul>
			</aside> -->
		</div>
		<!-- pc端内容 -->
		<div
			class="toplogin"
			v-else
			:style="{ height: topHeight ? topHeight : '' }"
		>
			<!-- 左右模式 -->
			<div
				class="home clearfix"
				v-if="!alginRight"
				:style="{ width: homeWidth ? homeWidth : '' }"
			>
				<ul class="nav-top fl clearfix">
					<slot name="nav-top-content-l"></slot>
					<!-- <top>
            在父组件中使用子组件的插槽 
            <template v-slot:nav-top-content-l>
              <p>这是父组件中使用的内容。</p>
            </template>
          </top>-->
					<li
						v-if="isLoginDefault != false"
						class="fl"
						v-show="isShowLogin"
					>
						<!-- 默认登录 -->
						<a
							:href="loginUrl"
							class="nav_a"
							:style="{ color: fontColor }"
							@click="login"
							>登录</a
						>
					</li>
					<!-- 默认退出登录 -->
					<li
						v-if="isLoginDefault != false"
						class="fl"
						v-show="isShowName"
					>
						<span
							href="javascript:;"
							class="nav_a"
							style="color: red; font-size: 16px"
							>{{ '欢迎您，' + userName }}</span
						>
						<a href="javascript:;" class="nav_a" @click="loginOut"
							>退出</a
						>
					</li>
					<li class="fl" v-show="isShowLogin">
						<a
							href="http://misc.drcnet.com.cn/DRCNet.Users.Web/User/UserPwdGet.aspx"
							:style="{ color: fontColor }"
							>忘记密码</a
						>
					</li>
					<li class="fl" v-show="isShowLogin">
						<a
							href="http://misc.drcnet.com.cn/DRCNet.Users.Web/User/UserReg1.aspx"
							:style="{ color: fontColor }"
							>注册</a
						>
					</li>
					<!-- 默认内容循环 -->
					<li
						class="fl"
						v-for="(item, index) in navButtonListLeft"
						:key="index"
					>
						<!-- color优先数组内的color 其次是父组件的fontcolor 最后是组件内的color -->
						<a
							:href="item.toLink"
							target="_blank"
							:style="{
								color: item.color
									? item.color
									: fontColor
									? fontColor
									: '',
								fontSize: fontSize ? fontSize : '',
								fontFamily: fontFamily ? fontFamily : '',
								fontWeight: item.fontWeight
									? item.fontWeight
									: 500,
							}"
							>{{ item.name }}</a
						>
					</li>

					<!-- <li class="fl">
          <a>繁体版</a>
          </li>-->
				</ul>
				<ul class="nav-top fr clearfix">
					<li
						class="fl"
						v-for="(item, index) in navButtonListRight"
						:key="index"
					>
						<!-- color优先数组内的color 其次是父组件的fontcolor 最后是组件内的color -->
						<a
							:href="item.toLink"
							target="_blank"
							:style="{
								color: item.color
									? item.color
									: fontColor
									? fontColor
									: '',
								fontSize: fontSize ? fontSize : '',
								fontFamily: fontFamily ? fontFamily : '',
							}"
							>{{ item.name }}</a
						>
					</li>
					<li class="fl" v-if="databaseDefalut != false">
						<el-dropdown placement="top">
							<span class="el-dropdown-link">
								<a :style="{ color: fontColor }">特色数据库</a>
							</span>
							<!-- 切记这里自定义了类名，不能重复 -->
							<el-dropdown-menu
								slot="dropdown"
								class="dropdownTopPbulicMenu"
							>
								<el-dropdown-item
									v-for="(item, index) in navDataBaseDropdown"
									:key="index"
									><a :href="item.toLink" target="_blank ">{{
										item.name
									}}</a></el-dropdown-item
								>
							</el-dropdown-menu>
						</el-dropdown>
						<!-- <a class="databaseTs">特色数据库</a>
						<div class="ts_nav">
							<em></em>
							<div class="clear"></div>
							<ul></ul>
						</div> -->
					</li>
					<slot name="nav-top-content-r"></slot>
				</ul>
			</div>
			<!-- 全部在右的模式 -->
			<div
				class="home clearfix"
				v-else
				:style="{ width: homeWidth ? homeWidth : '' }"
			>
				<ul class="nav-top fr clearfix">
					<slot name="nav-top-content-align-r"></slot>
					<!-- <top>
              在父组件中使用子组件的插槽 
              <template v-slot:nav-top-content-l>
                <p>这是父组件中使用的内容。</p>
              </template>
          </top>-->
					<li
						v-if="isLoginDefault != false"
						class="fl"
						v-show="isShowLogin"
					>
						<!-- 默认登录 -->
						<a
							:href="loginUrl"
							class="nav_a"
							:style="{ color: fontColor }"
							@click="login"
							>登录</a
						>
					</li>
					<!-- 默认退出登录 -->
					<li
						v-if="isLoginDefault != false"
						class="fl"
						v-show="isShowName"
					>
						<span
							href="javascript:;"
							class="nav_a"
							style="color: red; font-size: 16px"
							>{{ '欢迎您，' + userName }}</span
						>
						<a href="javascript:;" class="nav_a" @click="loginOut"
							>退出</a
						>
					</li>
					<li class="fl" v-show="isShowLogin">
						<a
							href="http://misc.drcnet.com.cn/DRCNet.Users.Web/User/UserPwdGet.aspx"
							:style="{ color: fontColor }"
							>忘记密码</a
						>
					</li>
					<li class="fl" v-show="isShowLogin">
						<a
							href="http://misc.drcnet.com.cn/DRCNet.Users.Web/User/UserReg1.aspx"
							:style="{ color: fontColor }"
							>注册</a
						>
					</li>
					<!-- 默认内容循环 -->
					<li
						class="fl"
						v-for="(item, index) in navButtonListLeft"
						:key="index"
					>
						<!-- color优先数组内的color 其次是父组件的fontcolor 最后是组件内的color -->
						<a
							:href="item.toLink"
							target="_blank"
							:style="{
								color: item.color
									? item.color
									: fontColor
									? fontColor
									: '',
								fontSize: fontSize ? fontSize : '',
								fontFamily: fontFamily ? fontFamily : '',
								fontWeight: item.fontWeight
									? item.fontWeight
									: 500,
							}"
							>{{ item.name }}</a
						>
					</li>

					<!-- <li class="fl">
          <a>繁体版</a>
          </li>-->
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { Popup, Cell, CellGroup, Collapse, CollapseItem, Icon } from 'vant';
import { isNullOrEmpty } from '@/utils/utils.js';
import {
	getName,
	setName,
	removeName,
	getToken,
	setToken,
	removeToken,
} from '@/utils/infomationStorage';
import { mapMutations, mapState } from 'vuex';
import { jsonp } from 'vue-jsonp';
export default {
	components: {
		Popup,
		Cell,
		CellGroup,
		Collapse,
		CollapseItem,
		Icon,
	},
	props: [
		'fontColor', //字体颜色，注意列表数据中中也可以接受color字段
		'fontSize', //字体大小
		'fontFamily', //字体
		'homeWidth', //组件整体宽度
		'topHeight', //组件整体高度
		'isLoginDefault', //登录是否是默认
		'addNavListLeft', //增加左侧navlist
		'addNavListRight', //增加右侧navlist
		'navListLeftDefalut', //左侧list是否使用默认值 如果为空或者true的话是显示,如果不想显示请传false
		'navListRightDefalut', //右侧list是否使用默认值 如果为空或者true的话是显示,如果不想显示请传false
		'databaseDefalut', //特色数据库是否使用
		'addDatabase', //追加database列表的值
		'defalutSort', //默认排序数组sort字段，因为执行过删除之后，数组的长度就变了，拼起来的最终数组的length的长度也变了，后面的sort也变，所以可能导致排序结果不理想（目前做法是如果在这个sort中没有出现总数组中sort字段的内容，就默认根据数字从小到大往后排）
		'deleteSort', //根据sort字段删除数组中的某些值，删除只能删除默认值，不能超出默认值的length
		'alginRight', //整体居右的情况(多数)
		'isMobileListDefault', // 手机端是否显示list集合（这里是把上面拼好的pc端的左右列表拼接了，所以如果pc端都没有的话这里也不会出现，这个属性是为了特殊情况使用插槽的时候）
		'isMobileDefault', //是否显示手机端的按钮和列表，这个选项false相当于频闭掉了移动端的导航
		'isMobile_pcView', //是否在移动端上显示pc版本的内容
	],
	/**传参实例
	 *		<topNavigation
			fontColor="red"
			fontSize="18px"
			fontFamily="宋体"
			topHeight="80px"
			homeWidth="800px"
			:isLoginDefault="true"
			:addNavListLeft="[
				{ name: '测试99', toLink: '' },
				{ name: '测试88', toLink: '' },
			]"
			:addNavListRight="[
				{ name: '测试1', toLink: '' },
				{ name: '测试2', toLink: '' },
			]"
			:navListLeftDefalut="true"
			:navListRightDefalut="true"
			:databaseDefalut="true"
			:addDatabase="[
				{ name: '测试22', toLink: '' },
				{ name: '测试33', toLink: '' },
			]"
			:defalutSort="[6, 2, 1, 4, 5, 3]"
			:deleteSort="[1, 2]"
			:alginRight="false"
			:isMobileDefault="true"
			:isMobileListDefault="true"
			:isMobile_pcView="false"
		></topNavigation>
	 */
	//目前没加hover的颜色
	//默认传入的和默认值共存，先拼接
	//登录默认会使用默认方法，如果需要修改请使用插槽
	//样式推荐在自己的样式表改，穿透一下
	data() {
		return {
			test: null,
			url: [
				'http://s1.drcnet.com.cn/search/page.aspx?fields=',
				'&rootlm=20556',
			],
			uid: '',
			loginUrl: '',
			isShowLogin: true,
			isShowName: false,
			userName: '',
			activeNames: [],
			//判断 手机端情况
			isMobile: false,
			//手机端左侧导航抽屉
			isDrawerOpen: false,
			//目前默认数组里面有6个值
			navButtonListLeft: [
				{
					name: '关于我们',
					toLink: 'http://company.drcnet.com.cn/about.html',
					sort: 1,
					color: '',
				},
				{
					name: '诚聘英才',
					toLink: 'http://company.drcnet.com.cn/cpyc.html#/recruit',
					sort: 2,
					color: '',
				},
				// { name: '旧版回顾', toLink: 'http://o.drcnet.com.cn', sort: 3 },
				{
					name: '公司网站',
					toLink: 'http://company.drcnet.com.cn',
					sort: 4,
					color: '',
				},
			],
			//导航右侧默认值
			navButtonListRight: [
				{
					name: '综合版',
					toLink: 'http://www.drcnet.com.cn',
					sort: 1,
					color: '',
				},
			],
			//特色数据库默认值
			navDataBaseDropdown: [
				{
					name: '国研网世界经济数据库系统',
					toLink: 'http://worldeconomy.drcnet.com.cn',
					sort: '1',
				},
				{
					name: '国研网“一带一路”研究与决策平台',
					toLink: 'http://ydyl.drcnet.com.cn',
					sort: '2',
				},
				{
					name: '经济·管理案例库平台',
					toLink: 'http://caselib.drcnet.com.cn',
					sort: '3',
				},
				{
					name: '文旅产业融合发展信息平台',
					toLink: 'http://mct.drcnet.com.cn',
					sort: '4',
				},
				{
					name: '战略性新兴产业及未来产业数据库平台',
					toLink: 'http://emerging.drcnet.com.cn',
					sort: '5',
				},
				{
					name: '国研网数字经济发展与观察平台',
					toLink: 'http://szjj.drcnet.com.cn',
					sort: '6',
				},
				{
					name: '政策法规库平台',
					toLink: 'http://policy.drcnet.com.cn',
					sort: '7',
				},
				{
					name: '企业大数据平台',
					toLink: 'http://www.entdata.com.cn',
					sort: '8',
				},
				{
					name: '乡村振兴大数据画像平台',
					toLink: 'http://xchx.drcnet.com.cn',
					sort: '9',
				},
				{
					name: '30·60双碳战略研究与决策支撑平台',
					toLink: 'http://carbon.drcnet.com.cn',
					sort: '10',
				},
				{
					name: '全球智库平台',
					toLink: 'https://thinktank.drcnet.com.cn/home',
					sort: '11',
				},
			],
			isMobileList: [],
		};
	},
	watch: {
		$route: {
			handler(to) {},
			immediate: true,
		},
		$props: {
			handler(to) {
				// console.log(to);
			},
			immediate: true,
		},
		MobileVerification: {
			handler(to) {
				this.isMobileVt();
				this.isMobileValue();
			},
			immediate: true,
		},
	},
	created() {
		// console.log(this.$props);
		// this.uid = this.$route.query.uid;
		this.logenter();
	},
	mounted() {
		let propsParam = this.$props;
		//第一步，判断是否使用左侧数组
		if (
			propsParam.navListLeftDefalut ||
			isNullOrEmpty(propsParam.navListLeftDefalut)
		) {
			//如果使用
			//第二部，拼接数组
			if (propsParam.addNavListLeft) {
				for (
					let index = 0;
					index < propsParam.addNavListLeft.length;
					index++
				) {
					const element = propsParam.addNavListLeft[index];
					this.navButtonListLeft.push({
						name: element.name,
						toLink: element.toLink,
						color: element.color ? element.color : '',
						fontWeight: element.fontWeight
							? element.fontWeight
							: '',
						sort: this.navButtonListLeft.length + 1,
					});
				}
			}
			//第三步，执行删除
			if (propsParam.deleteSort) {
				this.navButtonListLeft = this.navButtonListLeft.filter(
					(item) => !propsParam.deleteSort.includes(item.sort)
				);
			}
			//第四步，执行排序
			if (propsParam.defalutSort) {
				this.navButtonListLeft.sort((a, b) => {
					const sortA = propsParam.defalutSort.indexOf(a.sort);
					const sortB = propsParam.defalutSort.indexOf(b.sort);

					// 如果 a.sort 或 b.sort 不在 defalutSort 中，则放到末尾
					if (sortA === -1) return 1;
					if (sortB === -1) return -1;

					return sortA - sortB; // 正常排序
				});
			}
		} else {
			//如果不用默认数组 直接替换掉
			this.navButtonListLeft = propsParam.addNavListLeft;
		}
		//右侧的按钮内容 和左侧逻辑一致
		if (
			propsParam.navListRightDefalut ||
			isNullOrEmpty(propsParam.navListRightDefalut)
		) {
			if (propsParam.addNavListRight) {
				for (
					let index = 0;
					index < propsParam.addNavListRight.length;
					index++
				) {
					const element = propsParam.addNavListRight[index];
					this.navButtonListRight.push({
						name: element.name,
						toLink: element.toLink,
						sort: this.navButtonListRight.length + 1,
					});
				}
			}
		} else {
			this.navButtonListRight = propsParam.addNavListRight;
		}
		//追加特色数据库
		if (propsParam.databaseDefalut) {
			if (propsParam.addDatabase) {
				for (
					let index = 0;
					index < propsParam.addDatabase.length;
					index++
				) {
					const element = propsParam.addDatabase[index];
					this.navDataBaseDropdown.push({
						name: element.name,
						toLink: element.toLink,
						sort: this.navDataBaseDropdown.length + 1,
					});
				}
			}
		}
		// this.$nextTick(() => {
		// });

		// this.$nextTick(() => {
		// });

		if (getToken() !== null && getName() !== null) {
			this.isShowLogin = false;
			this.isShowName = true;
			this.userName = getName();
		} else {
			this.isShowLogin = true;
			this.isShowName = false;
			this.outloginMenu = true;
			this.loginMenu = false;
			this.menuMessage = '请您登录!';
		}
		let _this = this;
		this.$eventBus.$on('isDrawer', function (flags) {
			if (flags == false) {
				_this.isDrawerOpen = false;
			}
		});
	},
	methods: {
		login() {
			let url = 'https://user.drcnet.com.cn/#/login';
			this.loginUrl =
				url +
				'?gourl=' +
				window.location.href.replace('?', '~~').replace('&', '^');
			// 埋点
			try {
				//LogCollection.contents["key"]="value";
				//0：浏览（已在浏览器关闭、刷新、页面跳转时自动执行，此处可提前使之执行。）
				//2：收藏
				//3：下载
				//4：分享
				//5：购买
				//6：登录
				//7：注册
				//9：搜索
				//10：复制
				//11：点赞
				//12：评论
				LogCollection.send(6);
			} catch (e) {}
		},
		regLink(link) {
			window.open(link);
		},
		//手机端的登录
		isMobileLoginTo() {
			this.login();
			window.location.href = this.loginUrl;
		},
		//控制不懂端的导航条显示
		isMobileVt() {
			if (this.$props.isMobile_pcView == true) {
				this.isMobile = false;
			} else {
				this.isMobile = this.MobileVerification;
			}
		},
		//移动端数据控制
		isMobileValue() {
			//手机端列表 - 拼装全部已有内容
			if (this.isMobile) {
				//要做出左侧和右侧列表拼装
				this.isMobileList = this.navButtonListLeft.concat(
					this.navButtonListRight
				);
			} else {
				this.isMobileList = [];
			}
		},
		logenter() {
			let loginUrl =
				'http://user.drcnet.com.cn/userapi/loginController/initLoginP';
			//之前登录是d.drcnet.com.cn登录的，转发的登录，现在改为直接调用接口
			//使用vue-json解决$ajax不携带cookie的问题
			jsonp(loginUrl, {
				flag: 'initp',
				callbackName: 'showData',
				randomId: this.getRandom(),
			})
				.then((res) => {
					if (res.Logined === 1) {
						setName(res.UserName);
						setToken(res.UserToken);
						this.updateUserName(res.UserName);
						this.updateUserToken(res.UserToken);
						this.isShowLogin = false;
						this.isShowName = true;
						this.userName = getName();
						// window.parent.postMessage({ name: getName() }, '*');
					} else if (res.Logined === 0) {
						removeName();
						removeToken();
						this.updateUserName('defaultname');
						this.updateUserToken('notoken');
						this.isShowLogin = true;
						this.isShowName = false;
						// window.parent.postMessage({ name: '' }, '*');
						// this.$router.go(0);
					}
					resolve();
				})
				.catch((err) => {
					// console.log(err)
				});
		},
		//退出
		loginOut() {
			//https://user.drcnet.com.cn/userapi/loginController/logOutAll
			//http://user.drcnet.com.cn/userapi/loginController/initLoginP?flag=initp", //initp 查询目前用户登录状态，logoutp 退出登录
			let loginUrl =
				'http://user.drcnet.com.cn/userapi/loginController/logOutAllP';
			let _this = this;
			jsonp(loginUrl, { randomId: this.getRandom() })
				.then((res) => {
					_this.$nextTick(() => {
						removeName();
						removeToken();
						_this.updateUserName('defaultname');
						_this.updateUserToken('notoken');
						_this.isShowLogin = true;
						_this.isShowName = false;
						_this.$router.go(0);
					});
					// console.log(res);
					// removeName();
					// removeToken();
					// _this.updateUserName('defaultname');
					// _this.updateUserToken('defaulttoken');
					// _this.isShowLogin = true;
					// _this.isShowName = false;
					// _this.$router.go(0);
					// 判断本页面是否为首页
					// resolve();
				})
				.catch((err) => {
					_this.$nextTick(() => {
						removeName();
						removeToken();
						_this.updateUserName('defaultname');
						_this.updateUserToken('notoken');
						_this.isShowLogin = true;
						_this.isShowName = false;
						_this.$router.go(0);
					});
					// console.log(err);
					// removeName();
					// removeToken();
					// _this.updateUserName('defaultname');
					// _this.updateUserToken('defaulttoken');
					// _this.isShowLogin = true;
					// _this.isShowName = false;
					// _this.$router.go(0);
				});
			// this.$nextTick(() => {
			// 	removeName();
			// 	removeToken();
			// 	_this.updateUserName('defaultname');
			// 	_this.updateUserToken('defaulttoken');
			// 	_this.isShowLogin = true;
			// 	_this.isShowName = false;
			// 	// _this.$router.go(0);
			// });

			// let _this = this;
			// $.ajax({
			// 	url: 'http://user.drcnet.com.cn/userapi/loginController/logOutAll', //initp 查询目前用户登录状态，logoutp 退出登录
			// 	// url: `http://user.drcnet.com.cn/userapi/loginController/commonUserDocById?docId=${this.docId}&leafId=${this.leafId}`,
			// 	dataType: 'jsonp',
			// 	// jsonpCallback: 'showData', //指定回调函数名称
			// 	xhrFields: { withCredentials: true },
			// 	crossDomain: true,
			// 	async: true,
			// 	success: function (data) {
			// 		console.log(data);
			// 		removeName();
			// 		removeToken();
			// 		_this.updateUserName('defaultname');
			// 		_this.updateUserToken('defaulttoken');
			// 		_this.isShowLogin = true;
			// 		_this.isShowName = false;
			// 		_this.$router.go(0);
			// 	},
			// });
			// this.logenter();
		},
		//打开抽屉
		toggleDrawer() {
			this.isDrawerOpen = !this.isDrawerOpen;
			// if (this.isDrawerOpen) {
			// 	document.addEventListener('click', this.closeDrawer);
			// } else {
			// 	document.removeEventListener('click', this.closeDrawer);
			// }
		},
		// closeDrawer(event) {
		// 	if (!this.$el.contains(event.target)) {
		// 		this.isDrawerOpen = false;
		// 		document.removeEventListener('click', this.closeDrawer);
		// 	}
		// },
		//抽屉里面的跳转的方法
		handleLink(link) {
			window.open(link, '_blank');
		},
		//获取时间戳+随机数
		getRandom() {
			let timestamp = Date.now();
			let randomNum = Math.random();
			let combined = timestamp + '_' + randomNum.toFixed(6);
			return combined;
		},
		...mapMutations(['updateUserName']),
		...mapMutations(['updateUserToken']),
	},
};
</script>

<style lang="less" scoped>
.toplogin {
	height: 40px;
	background: #fff;
	line-height: 40px;
	color: #ccc;
	border-bottom: 1px solid #c9def3;
	display: flex;
	.nav-top {
		li {
			a {
				border-right: 2px solid #ccc;
				font-size: 13px;
				color: #666;
				padding: 0 15px;
				text-decoration: none;
				font-family: 'Microsoft YaHei';
			}
			a:hover {
				color: #c00;
			}
		}
		li:last-child a {
			border-right: 0px solid #ccc;
		}
	}
}
.nav {
	height: 45px;
	line-height: 45px;
	background: #134b84;
	li {
		float: left;
		a {
			cursor: pointer;
			color: #fff;
			font-size: 16px;
			padding: 0px 25px;
		}
		a:hover {
			color: #ff3 !important;
		}
	}
	li:first-child a {
		padding-left: 0;
	}
	li:last-child a {
		padding-right: 0;
	}
	li:nth-child(8) a {
		cursor: default;
		color: #f5da45;
		font-size: 20px;
		font-weight: 700;
		padding: 0px 23px;
		padding-left: 60px;
	}
	li:nth-child(8) a:hover {
		color: #f5da45 !important;
	}
	li:nth-child(9) a {
		border-left: 2px solid #f5da45;
		color: #f5da45;
		padding: 0px 24px;
	}
	li:nth-child(10) a {
		color: #f5da45;
		padding-left: 24px;
	}
	li:nth-child(11) a {
		color: #f5da45;
		padding-left: 24px;
	}
}
::v-deep .el-dropdown {
	.el-dropdown-link {
		cursor: pointer;
	}
}
.drawer-button {
	position: fixed;
	top: 20px;
	right: 20px;
	background: transparent;
	border: none;
	cursor: pointer;
	font-size: 24px;
	z-index: 100; /* 更高的层级 */
}
//移动端样式
/* 左侧悬浮抽屉导航样式 */
// aside {
// 	position: fixed;
// 	top: 0;
// 	right: 0;
// 	width: 250px;
// 	height: 100%;
// 	background-color: #fff;
// 	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
// 	transition: transform 0.3s ease;
// 	transform: translateX(-250px); /* 隐藏抽屉 */
// 	z-index: 101; /* 更高的层级 */
// }

// aside.show-drawer {
// 	transform: translateX(0); /* 显示抽屉 */
// }

// /* 导航链接样式 */
// aside ul {
// 	list-style: none;
// 	padding: 20px;
// }

// aside ul li {
// 	margin-bottom: 15px;
// }

// aside ul li a {
// 	text-decoration: none;
// 	color: #333;
// 	font-weight: bold;
// }
//公共样式-如果不引入公共的话也不会失效
ul {
	list-style: none;
}
.fl {
	float: left;
}

.fr {
	float: right;
} /* 清除浮动 */
.clearfix:after {
	/*伪元素是行内元素 正常浏览器清除浮动方法*/
	content: '';
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.clearfix {
	zoom: 1;
}

.home {
	width: 1200px;
	box-sizing: border-box;
	margin: 0 auto;
}
.userInfos {
	display: flex;
	padding: 10px 10px;
	padding-top: 20px;
	font-size: 16px;
	color: #1a1a1a;
	border-bottom: 1px solid #ececec;
	.userInfosLogin {
		width: 33%;
	}
	.userInfosInfo {
		width: 70%;
		max-width: 203px;
		margin-right: 10px;
		white-space: nowrap; //禁止换行
		overflow: hidden; //这个是设置隐藏的。还有其他的，例如scroll，是超出固定长度，底部显示滚动条的。
		text-overflow: ellipsis; //这个就是设置直接隐藏掉文字，还是显示...的。当前是显示省略号。直接省略是clip
		display: inline-block; //根据不同标签display值，有的不用加。
	}
	.userInfosOut {
		width: 20%;
	}
	.userInfosPass {
		width: 43%;
	}
	.userInfosReg {
		width: 33%;
	}
}
</style>

<style lang="less">
//类名不能重复 否则样式会乱 el-menu问题，因为他渲染在了整个页面上
.el-popper.dropdownTopPbulicMenu {
	// top: 67px !important;
	background-color: rgba(255, 255, 255, 1); // 背景颜色
	border: 1px #c9def3 solid;
	.el-dropdown-menu__item {
		a {
			//下拉列表中的a标签样式
			color: inherit; /* 继承父元素的文字颜色 */
			text-decoration: none; /* 去除下划线 */
		}
		border-bottom: 1px solid #e6e4e3;
	}
	.el-dropdown-menu__item:last-of-type {
		border-bottom: none !important;
	}
	// .popper__arrow::after {
	// 	border-bottom-color: #0f93ee !important;
	// 	border-top-color: #0f93ee !important;
	// }
	// .el-dropdown-menu__item:not(.is-disabled):hover {
	// 	background: #40a8ee !important;
	// }
}
</style>
