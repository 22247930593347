import axios from 'axios'
// export const baseUrl = 'http://newdrc.drcnet.com.cn/reportapi' //开发地址
// export const baseUrl = 'https://testgov.drcnet.com.cn/reportapi' //测试地址
export const baseUrl = 'https://government.drcnet.com.cn/reportapi'
const http = axios.create({
        baseURL: baseUrl,
        // baseURL: '/government',
        // withCredentials: true,
        // 请求 30s 超时s
        timeout: 300000
    })
    // 手动挂载2个方法
http.all = axios.all
http.spread = axios.spread
export function ajaxGetCode(url, params) {
    return http.get(url, params).then((res) => res);
}
export function ajaxPostCode(url, params) {
    return http.post(url, params).then((res) => res);
}
export function ajaxpostExport(url, data, fileName, type) {
    return http
        .post(url, data, {
            responseType: "blob",
        })
        .then((result) => {
            console.log("result", result);
            /*const contentDisposition = result.headers["content-disposition"];*/
            const filename = decodeURI(`${fileName}.${type}`);
            const blob = new Blob([result.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
        });
}

export default http