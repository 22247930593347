var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('topNavigation',{attrs:{"fontColor":"#666","fontSize":"13px","fontFamily":"","topHeight":"40px","homeWidth":"1101px","deleteSort":[4]}},[_c('template',{slot:"nav-top-content-isMobile-bottom"},[_c('ul',{staticClass:"m-content"},[_vm._l((_vm.nav),function(item,index){return [(index < 10)?_c('li',{key:item.uid,on:{"click":function($event){return _vm.clickClosePop()}}},[(item.uid == 'A301')?_c('a',{staticClass:"m-navtxt",style:({
                color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
              }),attrs:{"href":"http://drcresearch.drcnet.com.cn","target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A306')?_c('a',{staticClass:"navtxt",style:({
                color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
              }),attrs:{"href":"http://data.drcnet.com.cn","target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A308')?_c('a',{staticClass:"navtxt",style:({
                color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
              }),attrs:{"href":"http://misc.drcnet.com.cn/help/index.aspx","target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A307')?_c('router-link',{staticClass:"navtxt",style:({
                color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
              }),attrs:{"to":{ path: '/productIntro' }}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A300')?_c('router-link',{staticClass:"navtxt",style:({
                color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
              }),attrs:{"to":{ path: '/' }}},[_vm._v(" "+_vm._s(item.text)+" ")]):(
                item.uid == 'A302' ||
                item.uid == 'A303' ||
                item.uid == 'A304' ||
                item.uid == 'A305'
              )?_c('router-link',{staticClass:"navtxt",style:({
                color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
              }),attrs:{"to":{ path: '/list', query: { uid: item.uid, type: '0' } }}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A309')?_c('router-link',{staticClass:"navtxt",style:({
                color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
              }),attrs:{"to":{ path: '/NPCandCPPCC' }}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()],1):_vm._e()]}),_vm._l((_vm.setSimpleArray),function(item,index){return _c('SubNav',{key:index,attrs:{"item":item,"index":index,"type":item.listType}})})],2)])],2),_c('TopBanner',{attrs:{"contentWidth":"1101","height":"113","versionName":"[党政版]","versionNameVerticalAlign":"26","versionNameShow":"1","versionNameColor":"#ce342d","versionNameFontSize":"24","styleApi":"padding-top","logoMargin":"28","headerBackgroundImg":"none","headerContentBackgroundImg":"bg_header-government.png"}},[_c('template',{slot:"topBannerContent"},[_c('Search',{staticClass:"add",attrs:{"searchPosition":"1","selectType":"1,3,0","keyWordsInputTab":"请输入关键词","searchUrl":"0,20547","heighSearchUrl":"2,17041","searchBoxWidth":"418,0","searchInputWidth":"206,0","inputAndSelectWrapWidth":"281,0","simpleSearchIcon":"https://images.drcnet-sod.com/Images/Common/search_a.jpg","simpleSearchButton":"30,20,#9b7b7c,8,12","heighSearchButton":"30,20,#ff5237,8,12"}})],1)],2),(_vm.windowWidth > 769)?_c('div',{staticClass:"nav"},[_c('ul',{staticClass:"content1"},[_vm._l((_vm.nav),function(item,index){return [(index < 10)?_c('li',{key:item.uid},[(item.uid == 'A301')?_c('a',{staticClass:"navtxt",style:({
              'padding-left': index == 0 ? '22px' : 'none',
              color: index > 6 ? '#fbf58e' : '#fff',
            }),attrs:{"href":"http://drcresearch.drcnet.com.cn","target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A306')?_c('a',{staticClass:"navtxt",attrs:{"href":"http://data.drcnet.com.cn","target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A308')?_c('a',{staticClass:"navtxt",style:({
              'padding-left': index == 0 ? '22px' : 'none',
              color: index > 6 ? '#fbf58e' : '#fff',
            }),attrs:{"href":"http://misc.drcnet.com.cn/help/index.aspx","target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A307')?_c('router-link',{staticClass:"navtxt",style:({
              'padding-left': index == 0 ? '22px' : 'none',
              color: index > 6 ? '#fbf58e' : '#fff',
            }),attrs:{"to":{ path: '/productIntro' }}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A300')?_c('router-link',{staticClass:"navtxt",style:({
              'padding-left': index == 0 ? '22px' : 'none',
              color: index > 6 ? '#fbf58e' : '#fff',
            }),attrs:{"to":{ path: '/' }}},[_vm._v(" "+_vm._s(item.text)+" ")]):(
              item.uid == 'A302' ||
              item.uid == 'A303' ||
              item.uid == 'A304' ||
              item.uid == 'A305'
            )?_c('router-link',{staticClass:"navtxt",style:({
              'padding-left': index == 0 ? '22px' : 'none',
              color: index > 6 ? '#fbf58e' : '#fff',
            }),attrs:{"to":{ path: '/list', query: { uid: item.uid, type: '0' } }}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.uid == 'A309')?_c('router-link',{staticClass:"navtxt",style:({
              'padding-left': index == 0 ? '22px' : 'none',
              color: index > 6 ? '#fbf58e' : '#fff',
            }),attrs:{"to":{ path: '/databaseNav' }}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()],1):_vm._e()]})],2)]):_vm._e(),(_vm.windowWidth > 769)?_c('div',{staticClass:"nav2"},_vm._l((_vm.setSimpleArray),function(item,index){return _c('SubNav',{key:index,attrs:{"item":item,"index":index,"type":item.listType}})}),1):_vm._e(),_c('router-view'),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }