import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import windowSizeMixin from '@/mixins/windowSizeMixin'; //混入-浏览器窗口监听
import eventBus from '@/utils/eventBus'; //消息组件
Vue.prototype.$eventBus = eventBus;
Vue.use(ElementUI);
Vue.mixin(windowSizeMixin);
// 移动端引用
import 'vant/lib/index.css';
import { Popup, Button, Icon, Tab, Tabs, ActionSheet } from "vant";
Vue.prototype.$ArticleUrl = 'https://d.drcnet.com.cn/eDRCnet.common.web/DocDetail.aspx';
Vue.use(Popup);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(ActionSheet);
// import store from './store'

Vue.config.productionTip = false
new Vue({
    router,
    // store,
    render: h => h(App)
}).$mount('#app')