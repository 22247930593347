<template>
  <ul
    :style="{
      'margin-left':
        (index == 0 ? '30px' : '38px') &&
        (index == 5 || index == 7 ? '38px' : '48px'),
    }"
  >
    <li
      @click="clickClosePop()"
      v-for="subItem in item"
      :key="subItem.uid"
      :style="{
        width:
          subItem.uid == 'A31043'
            ? '82px'
            : 'auto' && subItem.uid == 'A31044'
            ? '109px'
            : 'auto',
      }"
    >
      <!-- v-else-if="item.uid == 'A302' || item.uid == 'A303' || item.uid == 'A304' || item.uid == 'A305'" -->
      <router-link
        v-if="subItem.uid != 'A31099'"
        :to="{
          path: '/list',
          query: { uid: subItem.uid, type: '1' },
        }"
        class="nav2txt"
      >
        <p>
          <span style="display: inline-block"> {{ subItem.text }}</span>
          <img
            style="display: inline-block"
            v-if="subItem.shownew == true"
            :src="require('../../assets/images/btnNew.png')"
            alt=""
          />
        </p>
      </router-link>
      <a v-else href="http://policy.drcnet.com.cn" class="nav2txt">
        <p>{{ subItem.text }}</p>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "subNav",
  props: {
    item: Array,
    index: Number,
    type: String,
  },
  methods: {
    clickClosePop() {
      this.$eventBus.$emit("isDrawer", false);
    },
  },
};
</script>

<style lang="less" scoped>
ul {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  .nav2txt {
    font-size: 14px;
    line-height: 18px;
    color: #333;
    p {
      margin-top: 8px;
      font-family: "微软雅黑";
    }
  }
}
@media screen and (max-width: 769px) {
  ul {
    float: none !important;
    margin-left: 15px !important;
  }
}
</style>