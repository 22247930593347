// src/mixins/windowSizeMixin.js
//混入，实时获取宽高并把判断是否是手机端，需要在调用的地方+watch
export default {
	data() {
		return {
			windowWidth: window.innerWidth, //视窗宽度
			windowHeight: window.innerHeight, //视窗高度
			MobileVerification: window.matchMedia('(max-width: 768px)').matches, //是否是手机端
			eduServerAddress: window.$eduServerAddress, //挂载教育版全局链接
			indexServerAddress: window.$indexServerAddress, //挂载综合版全局链接
			IdeologicalServerAddress: window.$IdeologicalServerAddress, //挂载思政版全局链接
			detailsLinkPage:
				'https://d.drcnet.com.cn/eDRCnet.common.web/DocDetail.aspx', //挂载跳转详情链接
		};
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		handleResize() {
			//媒体查询写法
			const isMobile = window.matchMedia('(max-width: 768px)').matches;
			this.MobileVerification = isMobile;
			//bom写法
			// this.MobileVerification =
			// 	window.innerWidth <= 769 ||
			// 	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			// 		navigator.userAgent
			// 	);
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
		},
		selectImgFromgetDocPicInfo(parentUid, chnid, fileId, homeImage) {
			if (parentUid == '1401') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/hongguan/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1405') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/quyu/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1403') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/hangye/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1402') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/jinrong/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1404') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/qiye/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1410') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/jiaoyu/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else {
				if (fileId == 0) {
					return 'https://www.drcnet.com.cn' + homeImage;
				} else {
					return (
						process.env.VUE_APP_BASE_INDEX_API +
						'/reportapi/DocCommonController/getDocPicInfo?docId=' +
						0 +
						'&fileId=' +
						fileId
					);
				}
			}
		},
		selectImgFromgetDocPicInfomode2(
			parentUid,
			chnid,
			fileId,
			homeImage,
			code
		) {
			if (parentUid == '1401') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/hongguan/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1405') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/quyu/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1403') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/hangye/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1402') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/jinrong/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1404') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/qiye/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else if (parentUid == '1410') {
				return (
					process.env.VUE_APP_IMG_ASSETS_BASE_URL +
					'/jiaoyu/' +
					chnid.toString().slice(-1) +
					'.jpg'
				);
			} else {
				if (fileId) {
					return `${process.env.VUE_APP_BASE_INDEX_API}/reportapi/DocCommonController/getDocPicInfo?docId=0&fileId=${fileId}`;
				} else {
					if (code) {
						return `${
							process.env.VUE_APP_BASE_INDEX_API
						}/reportapi/DocCommonController/getDocPicInfo?docId=${
							code.split('_')[1]
						}&fileId=0`;
					} else {
						return (
							process.env.VUE_APP_BASE_INDEX_API +
							'/reportapi/DocCommonController/getDocPicInfo?docId=' +
							0 +
							'&fileId=' +
							fileId
						);
					}
				}
			}
		},
	},
};
