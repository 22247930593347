import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    meta: {
        title: '党政版·首页'
    },
    component: () =>
        import ('../views/Home')
}, {
    path: '/list',
    name: 'list',
    meta: {
        title: '党政版·列表'
    },
    component: () =>
        import ('../views/list/index')
}, {
    path: '/productIntro',
    name: 'productIntro',
    meta: {
        title: '党政版·产品介绍'
    },
    component: () =>
        import ('../views/productIntro/index')
}, {
    path: '/databaseNav',
    name: 'databaseNav',
    meta: {
        title: '党政版·基础数据库导航'
    },
    component: () =>
        import ('../views/databaseNav/index')
}, {
    path: '/NPCandCPPCC',
    name: 'NPCandCPPCC',
    meta: {
        title: '党政版·特供两会专题'
    },
    component: () =>
        import ('../views/list/components/NPCandCPPCC/index')
}]

const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/',
})

// router.afterEach((to, from) => {
//     try {
//         if (LogCollection) {
//             LogCollection.init();
//             console.log(LogCollection.init());
//         }
//     } catch (error) {
//         console.log(error);
//     }
// })
export default router