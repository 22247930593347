import http from "./index.js"
// 导航接口
export function getNav(params) {

    return http({
        method: "get",
        url: '/WebChannelCommonController/getAllSonMenus',
        params,
    })
}
// 文章接口
export function getDocList(params) {
    return http({
        method: "get",
        url: '/GovVersionController/getGovIndexDocs',
        params,
    })
}
// 热点专题
export function getHotTopics(params) {
    return http({
        method: "get",
        url: '/DocCommonController/getIndexHotTopicDocs',
        params,
    })
}
// 获取文章列表
export function getArticleList(params) {
    return http({
        method: 'get',
        url: '/DocCommonController/getDocsByUids',
        params,
    })
}
// 获取专家解析数据
export function getExpertStore(params) {
    return http({
        method: 'get',
        url: '/DocCommonController/getExpertList',
        params,
    })
}
// 获取季刊数据
export function getQuarterly(params) {
    return http({
        method: 'get',
        url: '/DocCommonController/getEduIndexInfoList',
        params,
    })
}
// 获取两会专题数据
export function getNPCandCPPCCDColumn(params) {
    return http({
        method: 'get',
        url: '/DocCommonController/getIndexLHTopicDocs',
        params,
    })
}
// 获取去两会专题右侧栏目列表
export function getChnidMenu(params) {
    return http({
        method: 'get',
        url: '/WebChannelCommonController/getMenusByChnid',
        params,
    })
}