var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{style:({
    'margin-left':
      (_vm.index == 0 ? '30px' : '38px') &&
      (_vm.index == 5 || _vm.index == 7 ? '38px' : '48px'),
  })},_vm._l((_vm.item),function(subItem){return _c('li',{key:subItem.uid,style:({
      width:
        subItem.uid == 'A31043'
          ? '82px'
          : 'auto' && subItem.uid == 'A31044'
          ? '109px'
          : 'auto',
    }),on:{"click":function($event){return _vm.clickClosePop()}}},[(subItem.uid != 'A31099')?_c('router-link',{staticClass:"nav2txt",attrs:{"to":{
        path: '/list',
        query: { uid: subItem.uid, type: '1' },
      }}},[_c('p',[_c('span',{staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(subItem.text))]),(subItem.shownew == true)?_c('img',{staticStyle:{"display":"inline-block"},attrs:{"src":require('../../assets/images/btnNew.png'),"alt":""}}):_vm._e()])]):_c('a',{staticClass:"nav2txt",attrs:{"href":"http://policy.drcnet.com.cn"}},[_c('p',[_vm._v(_vm._s(subItem.text))])])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }