<template>
  <div id="app">
    <!-- 功能导航 -->
    <topNavigation
      fontColor="#666"
      fontSize="13px"
      fontFamily=""
      topHeight="40px"
      homeWidth="1101px"
      :deleteSort="[4]"
    >
      <template slot="nav-top-content-isMobile-bottom">
        <!-- m_上方导航 -->
        <ul class="m-content">
          <template v-for="(item, index) in nav">
            <li @click="clickClosePop()" :key="item.uid" v-if="index < 10">
              <a
                v-if="item.uid == 'A301'"
                href="http://drcresearch.drcnet.com.cn"
                target="_blank"
                class="m-navtxt"
                :style="{
                  color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
                }"
              >
                {{ item.text }}
              </a>
              <a
                v-else-if="item.uid == 'A306'"
                href="http://data.drcnet.com.cn"
                target="_blank"
                class="navtxt"
                :style="{
                  color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
                }"
              >
                {{ item.text }}
              </a>
              <a
                v-else-if="item.uid == 'A308'"
                href="http://misc.drcnet.com.cn/help/index.aspx"
                target="_blank"
                class="navtxt"
                :style="{
                  color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
                }"
              >
                {{ item.text }}
              </a>
              <router-link
                v-else-if="item.uid == 'A307'"
                :to="{ path: '/productIntro' }"
                class="navtxt"
                :style="{
                  color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
                }"
              >
                {{ item.text }}
              </router-link>
              <router-link
                v-else-if="item.uid == 'A300'"
                :to="{ path: '/' }"
                class="navtxt"
                :style="{
                  color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
                }"
              >
                {{ item.text }}
              </router-link>
              <router-link
                v-else-if="
                  item.uid == 'A302' ||
                  item.uid == 'A303' ||
                  item.uid == 'A304' ||
                  item.uid == 'A305'
                "
                :to="{ path: '/list', query: { uid: item.uid, type: '0' } }"
                class="navtxt"
                :style="{
                  color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
                }"
              >
                {{ item.text }}
              </router-link>
              <router-link
                v-else-if="item.uid == 'A309'"
                :to="{ path: '/NPCandCPPCC' }"
                class="navtxt"
                :style="{
                  color: index > 6 ? 'rgb(255, 82, 55)' : '#333',
                }"
              >
                {{ item.text }}
              </router-link>
            </li>
          </template>
          <!-- m_下方导航 -->
          <SubNav
            v-for="(item, index) in setSimpleArray"
            :key="index"
            :item="item"
            :index="index"
            :type="item.listType"
          ></SubNav>
        </ul>
      </template>
    </topNavigation>
    <!-- 顶部banner pc端 -->
    <TopBanner
      contentWidth="1101"
      height="113"
      versionName="[党政版]"
      versionNameVerticalAlign="26"
      versionNameShow="1"
      versionNameColor="#ce342d"
      versionNameFontSize="24"
      styleApi="padding-top"
      logoMargin="28"
      headerBackgroundImg="none"
      headerContentBackgroundImg="bg_header-government.png"
    >
      <template slot="topBannerContent">
        <Search
          class="add"
          searchPosition="1"
          selectType="1,3,0"
          keyWordsInputTab="请输入关键词"
          searchUrl="0,20547"
          heighSearchUrl="2,17041"
          searchBoxWidth="418,0"
          searchInputWidth="206,0"
          inputAndSelectWrapWidth="281,0"
          simpleSearchIcon="https://images.drcnet-sod.com/Images/Common/search_a.jpg"
          simpleSearchButton="30,20,#9b7b7c,8,12"
          heighSearchButton="30,20,#ff5237,8,12"
        />
      </template>
    </TopBanner>
    <!-- 导航 -->
    <div class="nav" v-if="windowWidth > 769">
      <ul class="content1">
        <template v-for="(item, index) in nav">
          <li :key="item.uid" v-if="index < 10">
            <a
              v-if="item.uid == 'A301'"
              href="http://drcresearch.drcnet.com.cn"
              target="_blank"
              class="navtxt"
              :style="{
                'padding-left': index == 0 ? '22px' : 'none',
                color: index > 6 ? '#fbf58e' : '#fff',
              }"
            >
              {{ item.text }}
            </a>
            <a
              v-else-if="item.uid == 'A306'"
              href="http://data.drcnet.com.cn"
              target="_blank"
              class="navtxt"
            >
              {{ item.text }}
            </a>
            <a
              v-else-if="item.uid == 'A308'"
              href="http://misc.drcnet.com.cn/help/index.aspx"
              target="_blank"
              class="navtxt"
              :style="{
                'padding-left': index == 0 ? '22px' : 'none',
                color: index > 6 ? '#fbf58e' : '#fff',
              }"
            >
              {{ item.text }}
            </a>
            <router-link
              v-else-if="item.uid == 'A307'"
              :to="{ path: '/productIntro' }"
              class="navtxt"
              :style="{
                'padding-left': index == 0 ? '22px' : 'none',
                color: index > 6 ? '#fbf58e' : '#fff',
              }"
            >
              {{ item.text }}
            </router-link>
            <router-link
              v-else-if="item.uid == 'A300'"
              :to="{ path: '/' }"
              class="navtxt"
              :style="{
                'padding-left': index == 0 ? '22px' : 'none',
                color: index > 6 ? '#fbf58e' : '#fff',
              }"
            >
              {{ item.text }}
            </router-link>
            <router-link
              v-else-if="
                item.uid == 'A302' ||
                item.uid == 'A303' ||
                item.uid == 'A304' ||
                item.uid == 'A305'
              "
              :to="{ path: '/list', query: { uid: item.uid, type: '0' } }"
              class="navtxt"
              :style="{
                'padding-left': index == 0 ? '22px' : 'none',
                color: index > 6 ? '#fbf58e' : '#fff',
              }"
            >
              {{ item.text }}
            </router-link>
            <router-link
              v-else-if="item.uid == 'A309'"
              :to="{ path: '/databaseNav' }"
              class="navtxt"
              :style="{
                'padding-left': index == 0 ? '22px' : 'none',
                color: index > 6 ? '#fbf58e' : '#fff',
              }"
            >
              {{ item.text }}
            </router-link>
            <!-- <a
              
              href=""
              target="_blank"
              class="navtxt"
              :style="{
                'padding-left': index == 0 ? '22px' : 'none',
                color: index > 6 ? '#fbf58e' : '#fff',
              }"
            >
              {{ item.text }}
            </a> -->
            <!-- <router-link to="/" class="navtxt" style="padding-left: 22px"
              >首页</router-link
            > -->
          </li>
        </template>
      </ul>
    </div>
    <!-- 导航下方选项块 -->
    <div class="nav2" v-if="windowWidth > 769">
      <SubNav
        v-for="(item, index) in setSimpleArray"
        :key="index"
        :item="item"
        :index="index"
        :type="item.listType"
      ></SubNav>
    </div>
    <!-- 移动端导航 -->
    <!-- <div class="m_top_nav" v-if="windowWidth < 769">
      <van-button
        icon="list-switch"
        class="popUpShowRight"
        @click="popUpShowRight = true"
      />
      <van-popup
        v-model="popUpShowRight"
        position="left"
        :style="{ width: '70%', height: '100%' }"
      >
        
      </van-popup>
    </div> -->
    <!-- 路由 -->
    <router-view></router-view>
    <!-- footer -->
    <Footer></Footer>
  </div>
</template>
<script>
import "@/assets/css/base.css";
import topNavigation from "@/components/topNavigation.vue";
import TopBanner from "@/components/TopBanner";
import Search from "@/components/Search";
import { getNav } from "./require/api";
import SubNav from "./components/SubNav/index.vue";
import Footer from "./components/Footer/index.vue";
export default {
  name: "App",
  components: {
    topNavigation,
    TopBanner,
    Search,
    SubNav,
    Footer,
  },
  data() {
    return {
      nav: [],
      // 导航下方选项块
      simpleNav: [],
      setSimpleArray: [],
      //移动端数据
      popUpShowRight: false,
    };
  },
  created() {
    this.getNavData();
  },
  methods: {
    // 获取导航数据
    async getNavData() {
      const {
        data: { data },
      } = await getNav({ uid: "A3" });
      this.nav = data.data.children;
      // 导航栏目下方选项=》数据挂在在最后导航菜单的最后一项
      this.simpleNav =
        data.data.children[data.data.children.length - 2].children;
      // 处理数据并存储为长度为三的数组保存在setSimpleArray中
      // for (let i = 0; i < this.simpleNav.length; i += 4) {
      //   console.log(this.simpleNav[i]);
      //   if (this.simpleNav[i].isnav == true) {

      //     this.setSimpleArray.push(this.simpleNav.slice(i, i + 4));
      //   }
      // }
      let isNavTrue = [];
      for (let i = 0; i < this.simpleNav.length; i++) {
        if (this.simpleNav[i].isnav == true) {
          isNavTrue.push(this.simpleNav[i]);
        }
      }
      if (isNavTrue.length > 0) {
        for (let i = 0; i < isNavTrue.length; i += 4) {
            this.setSimpleArray.push(isNavTrue.slice(i, i + 4));
        }
      }
    },
    // test
    clickClosePop() {
      this.$eventBus.$emit("isDrawer", false);
    },
  },
};
</script>
<style lang="less" scoped>
.add {
  position: absolute;
  right: 0;
  bottom: 30px;
}
.nav {
  background: url(./assets/images/navs.png) repeat-x;
  bottom: 0px;
  font-size: 16px;
  .content1 {
    width: 1111px;
    margin: auto;
    overflow: hidden;
    li {
      float: left;
      width: 110px;
      .navtxt {
        font-family: "微软雅黑";
        color: #fff;
        font-size: 16px;
        line-height: 41px;
        letter-spacing: 1px;
      }
    }
  }
}
.nav2 {
  width: 1101px;
  margin: 0 auto;
  border: 1px #ac0002 solid;
  border-top: none;
  overflow: hidden;
  padding-bottom: 6px;
  overflow: hidden;
}
@media screen and (max-width: 769px) {
  .add {
    position: absolute;
    right: 0;
    bottom: -40px;
  }
  .popUpShowRight {
    position: absolute;
    left: 8px;
    top: 10px;
    background: none !important;
    border: none !important;
  }
  .van-button__content {
    color: black;
    font-size: 24px;
    font-weight: bolder;
  }
  .m-subNav {
    padding: 16px;
  }
  .m-content {
    padding: 16px;
    li {
      text-align: left;
      padding: 0 16px;
      line-height: 36px;
      border-bottom: 1px solid rgba(102, 102, 102, 0.1);
      .m-navtxt {
        color: #333;
      }
    }
  }
}
</style>

